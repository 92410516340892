.App {
  text-align: center;
  font-family: inter, arial, sans-serif;
}

.main {
  /* max-width: 1000px; */
  /* margin: 10px; */
  font-family: inter, arial, sans-serif;
  margin-top: 0em;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
#basic-navbar-nav a {
  color: white !important;
}
a :active {
  color: #019efc;
}

a:link { text-decoration: none; }

.navbar-toggler {
  padding: 0.25rem 1rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #019efc;
  border: 1px solid white;
  border-radius: 0.25rem;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navigation-container {
  text-align: center;
}

.table-container {
  text-align: center;
  margin-top: 10px;
  min-height: 500px;
}
 

#basic-navbar-nav {
  /* min-width: 1000px; */
  font-weight:normal;
  border:'1px solid red';
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.races-container {
  border-radius: 10px;
  text-align: center;
  margin-top: 10px;
  border: 1px dashed gray;
}

.edit-container {
  max-width: 500px;
  text-align: left;
}

@media (max-width: 600px) {
  #mainLogo {
    display: none;
  }

  .nav-wrapper
  {
    position: relative ;   
    background-image: linear-gradient(black, gray); 
  }
}

@media (min-width: 601px) {

  .nav-wrapper
  {
    /* position: sticky ; */
    top: 0;    
    z-index: 2;
  }
}


.textbox-container {
  display: flex;
  justify-content: space-evenly;
}
.textbox-container .label-div {
  text-align: right;
  border: 1px solid red;
}
.textbox-container .field-div {
  text-align: center;
  border: 1px solid blue;
}

.flex-outer,
.flex-inner {
  list-style-type: none;
  padding: 0;
}

.flex-outer {
  max-width: 800px;
  margin: 0 auto;
}

.flex-outer li,
.flex-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: left;
}

.flex-inner {
  padding: 0 8px;
  justify-content: space-between;
}

.flex-outer > li:not(:last-child) {
  margin-bottom: 5px;
}

.flex-outer li label {
  text-align: left;
}

.flex-outer li p {
  padding: 8px;
  font-weight: 300;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

.flex-outer > li > label,
.flex-outer li p {
  flex: 1 0 120px;
  max-width: 220px;
}

.flex-outer > li > label + *,
.flex-inner {
  flex: 1 0 300px;
}

.react-datepicker-wrapper {
  text-align: left;
}
.flex-outer li p {
  margin: 0;
}

.flex-outer li input:not([type="checkbox"]) {
  padding: 5px;
  border: 1px solid grey;
}
.flex-outer li textarea {
  padding: 5px;
  border: 1px solid grey;
}

.flex-outer li button {
  margin-left: auto;
  padding: 8px 16px;
  border: none;
  background: #333;
  color: #f2f2f2;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  border-radius: 2px;
}

.flex-inner li {
  width: 100px;
}

.showTop3 {
  font-family: monospace;
  text-align: left;
}
.hideTop3 {
  display: none;
}
.admin-container .card {
  margin-top: 10px;
  margin-bottom: 10px;
}
.card{
  width: auto;
  border:none;
  align-items: center;
  
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.nav-active {
  color: white;
  font-weight: "bolder";
  background-color: red !important;
}

.navbar {
  padding:5px 5px;
}
.jumbotron {
  margin-bottom: 10px;
  margin-top: 10px;
  /* background-color: #e6f0ff; */
  border-radius: 10px;
  padding: 2rem 2rem;
  border: 1px solid #d3d3d3;
}
.table-header {
  background-color: #e6f0ff;
}

.detail-ta {
  height: 300px;
}
.contact-ta {
  height: 150px;
}
.login-div {
  text-align: right;
}
.api-version {
  text-align: right;
  color: gray;
  font-size: 12px;
}

caption {
  caption-side: top;
  font-weight: bold;
}

.pigeon-connect {
  color: lightsteelblue;
  font-family: sans-serif;
  font-size: 10px;
  font-stretch: extra-expanded;
}
.pigeon-connect a {
  color:'#CD5C5C' !important;
}
.footer-container {
  color: white;
  font-family: Georgia, serif;

  font-size: 16px;
}

.footer-container a {
  text-decoration: none;
  color: black;
}
h2 {
  font-family: Georgia, "Times New Roman", Times, serif;
}
.lead {
  font-family: Georgia, "Times New Roman", Times, serif;
}
/* .circle {
  width: 100px;
  height: 100px;
  background: red;
  border-radius: 50%;
} */

@media (max-width: 600px) {
  .trapezoid {
    border-bottom: 85px solid #019efc;
    border-left: 50px solid transparent;
    border-right: 0px solid transparent;
    height: 0;
    width: auto;
  }
}
/* @media (min-width: 601px) {
  .rectangle {
    width: auto;
    height: 125px;
    background: #343a40;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
}

@media (max-width: 600px) {
  .rectangle {
    width: auto;
    height: 90px;
    background: #343a40;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
} */
.rectangle {
  width: auto;
  height: 125px;
  background: #343a40;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.rectangle span {
  font-size: 12px;
  font-family: sans-serif;
}

/*.trapezoid {
  border-bottom: 85px solid #019efc;
  border-left: 50px solid transparent;
  border-right: 0px solid transparent;
  height: 0;
  width: 1000px;
} */
.copy-right {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.manage-view {
  border: 1px solid white;
  padding: 10px;
}

.navbar-toggler:after {
  font-weight: lighter;
  content: "Menu";
  color: white;
  position: absolute;
  margin-left: 20px;
}

.spacer {
  padding-top: 25px;
  padding-bottom: 25px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.adbox-container
{
  min-height: 180px;
  
/* background-color: #343a40; */
background-color: white;
text-align: center;
z-index: 900;
}

.fontPrimary
{
font-family: Arial, Helvetica, sans-serif;
white-space: pre-wrap;

}
.navbar .dropdown-menu {
  color:#343a40
}


/* and this styles the dropdwon trigger link, when open */
.navbar .dropdown.show a { 
  
  color:#343a40
}

#cjcLogoMain {display:block; height: 100px; width: auto;margin-left: auto;margin-right: auto;}
#cjcLogoMobile {display:none}

@media all and (max-width: 499px) {
    #cjcLogoMain {display: none;}
    #cjcLogoMobile {display: block; height: 100px; width: auto;margin-left: auto;margin-right: auto;}
}
/* 
margin-left: auto;
margin-right: auto;
width: 50%; */

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}

.nav-sponsor-animated {
  animation: bounce 2s infinite;
  font-weight: bold;
  color: #ffc107 !important; /* gold/yellow */
}
/* Pulse Effect */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.nav-sponsor-pulse {
  animation: pulse 1.8s infinite;
  color: #ffc107 !important;
  font-weight: bold;
}

.nav-sponsor-shimmer {
  background: linear-gradient(90deg, #ffd700, #fff, #ffd700);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmer 2s linear infinite;
  font-weight: bold;
}

@keyframes shimmer {
  to {
    background-position: -200% center;
  }
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(15deg); }
  30% { transform: rotate(-10deg); }
  45% { transform: rotate(10deg); }
  60% { transform: rotate(-6deg); }
  75% { transform: rotate(4deg); }
  100% { transform: rotate(0deg); }
}

.nav-sponsor-shake {
  display: inline-block;
  animation: shake 2.5s infinite;
  color: #ffcc00 !important; /* Bright yellow */
  font-weight: bold;
}

.icon-shake {
  display: inline-block;
  animation: shake 2s infinite;
}